<template>
  <div class="table-container">
    <b-table
      :data="items"
      :loading="loading"
      striped
      hoverable
      bordered
      detailed
      detail-key="id"
      paginated
      backend-pagination
      backend-filtering
      backend-sorting
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      :current-page="page"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      @filters-change="filtersChange"
      :row-class="(row, index) => row.status"
    >
      <template slot-scope="props">
        <b-table-column
          field="index"
          label="Index"
        >{{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}</b-table-column>

        <b-table-column
          field="created_at"
          label="Created at"
          sortable
        >{{ props.row.created_at | moment('D.M.YYYY H:mm:ss') }}</b-table-column>
        <b-table-column field="type" label="Type" sortable searchable class="status">{{ props.row.type }}</b-table-column>

        <b-table-column field="status" label="Status" sortable searchable class="status">{{ props.row.status }}</b-table-column>

        <b-table-column
          field="serial_number"
          label="Serial number"
          sortable
          searchable
        >{{ props.row.serial_number && props.row.serial_number.join(', ') }}</b-table-column>

        <b-table-column field="id" label="ID" sortable>{{ props.row.id }}</b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <div class="field">
          <vue-json-pretty :data="props.row"></vue-json-pretty>
          <b-button size="is-small" class="bd-copy" v-on:click="doCopy(props.row)">copy</b-button>
        </div>
      </template>

      <template slot="bottom-left">
        <b-button
          v-on:click="fetch"
          type="is-info"
          icon-left="refresh"
          outlined
          style="margin-left: 100px"
        >Refresh data</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { isEqual, pickBy, assign } from 'lodash'; // assign

export default {
  name: 'LogProvisioning',
  data() {
    return {
      items: [],
      total: 0,
      perPage: 20,
      sortField: 'created_at',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      loading: false,
      offset: 0,
    };
  },
  created() {
    this.fetch();
  },
  computed:
  {
    page() { return parseInt(this.$route.query.page, 10) || 1; },
    query() { return this.$route.query; },
  },
  watch: {
    query() {
      console.log('watch query', this.query);
      this.fetch();
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.offset = (this.page - 1) * this.perPage;

      const params = Object.assign({}, this.query, {
        sort_field: this.sortField,
        sort_order: this.sortOrder,
        limit: this.perPage,
        offset: this.offset,
      });

      const res = await this.$http.get('api/v1/provisioning/log', { params });
      this.items = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
    onPageChange(newPage) {
      const query = assign({}, this.query, { page: newPage });
      this.$router.replace({ query });
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
    doCopy(message) {
      const toast = this.$toast;
      this.$copyText(JSON.stringify(message, null, 2)).then(
        () => {
          toast.success('Copied to clipboard');
        },
        () => {
          toast.error('Can not copy');
        }
      );
    },
    filtersChange(filters) {
      const params = pickBy(filters, (v) => v.length > 2);

      if (!isEqual(params, this.query)) {
        this.$router.replace({ query: params });
      }
    },
  },
};
</script>

<style>
.bd-copy {
  background: 0 0;
  border: none;
  cursor: pointer;
  font-size: 0.625rem;
  outline: 0;
  padding-bottom: 0;
  padding-top: 0;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
}
.bd-copy:hover {
  background-color: #ffdd57;
  color: rgba(0, 0, 0, 0.7);
}
.bd-copy:focus {
  box-shadow: none !important;
}
.field {
  position: relative;
}
tr.Pass td.status {
  color: green;
}
tr.Error td.status {
  color: red;
}
</style>
